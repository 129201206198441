.content-bea {
    max-width: 825px;
    margin: 2rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-bea-form {
    max-width: 875px; 
    margin: 0 auto; 
    padding: 50px; 
    /* border: 1px solid #ccc;  */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px
}

body {
    background-color: #F8F8F8;
}

.success-msg-div-bea{
     display: flex; 
     gap: 3rem; 
     justify-content: center; 
     margin: 2.5rem auto;
}

.success-msg-alert{
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 10rem;
}

.capctha-container-div{
    display: flex; 
    width: fit-content; 
    align-self: flex-start;
}