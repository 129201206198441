.news-container {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: space-around;
    width: 85%;
    margin-top: 30px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Media query for screens with a maximum width of 600px */
  @media (max-width: 600px) {
    .news-container {
      width: 90%;
      height: auto;
      padding: 0;
    }
    .news-inner-container{
      width: 100%;
    }
  }
  