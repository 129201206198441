.avatarImage {
    width: 200px;
    height: 200px;
}

.advisory-board-quote {
    width: 700px;
}

h6 {
    color: #106680;
    margin-top: 2rem;
}

.parent-ctn-for-spice-team {
    /* max-width: fit-content;
    margin-left: auto;
    margin-right: auto; */
    max-width: 1000px;
    margin: 2rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .container-for-members-of-team-spice{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    
}

.team-member-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

.container-for-members-of-team-spice {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Centers items */
    align-items: center;
    gap: 10px;
    /* Space between items */
    max-width: 900px;
    /* Ensures proper wrapping */
    margin: 2.5px auto;
    /* Centers the grid horizontally */
    padding: 10px;
    
}

.team-member-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 7px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1 1 calc(50% - 30px);
    max-width: 45%;
    min-width: 200px;
    padding: 10px;

}

.team-member-box-c {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 7px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    flex: 1 1 calc(33.33% - 30px);
    width: 85%;

}