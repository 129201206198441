.content-cu {
    max-width: 825px;
    margin: 2rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);   
}

.parent-container-cu{
    display: flex; 
    gap: 3rem; 
    justify-content: center; 
    margin: 15rem auto;
}

.child-container-cu{
    display: flex; 
    flex-direction: column; 
    gap: 5px;
}

.alert-element-cu{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 10rem;
}

.paragraph-cu{
    font-size: 1.1rem; 
    margin-bottom: 0.1rem;
}

.warning-msg-span-cu{
    color: red;
}