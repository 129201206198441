.footer-box {
    padding: 40px 30px;
    background: /*#2B3B85*/gainsboro;
    margin-top: 2.5vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.footer-link {
    color: #000;
}

.footer-link .MuiSvgIcon-root:hover {
    color: #2B3B85;
    transition: 200ms ease-in;
}

.footer-heading {
    font-size: 24px;
    color: #2B3B85;
    margin-bottom: 40px;
    font-weight: bold;
}

.footer-paragraph{
    font-weight: 700; 
    color: #2B3B85;
}

.find-us-container{
    display: flex; 
    align-items: center; 
    justify-content: space-between;
}

.find-us-header{
    font-weight: 700; 
    color: #2B3B85;
}

.small-footer{
    color: #2B3B85; 
    text-align: center; 
    margin-top: 2vh;
}

@media (max-width: 1000px) {
    .footer-box {
        padding: 20px 10px;
    }

    .footer-container {
        max-width: 80vw;
    }
}

.developed-by-link{
    color:#2B3B85; 
    font-weight: 600;
}