#buttons-container {
    display: flex;
    gap: 7.5px;
    margin: 20px;
    justify-content: space-evenly;
}

.cta-button {
    color: #fff;
    border: 3.5px solid #fff;
    text-transform: none;
    background-color: transparent;
    transition: background-color 0.3s, color 0.3s;
    width: 225px;
    padding: 7.5px;
}

.cta-button:hover {
    background-color: #fff;
    color: #6c94a6;
}

#cta-container {
    background-image: url('/src/banner2.png');   
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 1px;
}

#cta-texts-container {
    display: flex;
    flex-direction: column;
    color: cornflowerblue;
    margin: 25px auto;
    width: 100%;
}

.cta-texts {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    font-size: 1rem;
    margin: -2.5px 15vw;
    text-align: center;
    color: #fff;
    padding: 10px;
}

#cta-heading{
    font-size: 1.5rem;
}

@media (max-width: 600px) {
    #cta-container{
        padding-bottom: 20px;;
    }
    #cta-texts-container {
        width: 100%;
        text-align: center;
    }

    .cta-texts {
        font-size: 1rem;
        margin: 0px;
        padding: 10px;
    }

    #cta-heading{
        font-size: 1.25rem;
    }
    #buttons-container {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin: 5px;
    }

    .cta-button{
        width: 80%;
        margin-bottom: 5px;
    }

}