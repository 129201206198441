/* .story-post-container-div{
    margin-top: 5vh; 
    width: 450px; 
    height: 550px; 
    margin-left: auto; 
    margin-right: auto; 
    align-items: center; 
    text-align: center;
} */

.content-wat{
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
h2 {
    color: #106680;
}
h4 {
    font-size: 1.25rem;
    color: #106680;
    margin-top: 1.5rem;
}


.image-from-content{
    width: 410px;
    height: 360px;

    display: block;
    margin: 0 auto; /* Centers the image horizontally */
  

}