.content-p {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.intro {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

h1 {
    font-size: 1.5rem;
    color: #106680;
    margin-top: 2rem;
}

h4 {
    font-size: 1rem;
    color: #106680;
    margin-top: 2rem;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

ul.partnerships, ul.collaborations {
    margin: 0.5rem 0 1.5rem 0;
    
}

ul.partnerships-child {
    font-size: small;   
    list-style-type: square;
}
ul.partnerships-child::marker {
    color: '#378FA7'
}
ul li {
    margin-bottom: 0.5rem;
}

.contact {
    margin-top: -0.5rem;
    font-size: 1.1rem;
}

.contact a {
    color: #4aa2c1;
    text-decoration: none;
}

.contact a:hover {
    text-decoration: underline;
}