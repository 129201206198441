body {
  background-color: #fff;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.content {
  flex: 1;
  padding-top: 7rem; /* Add padding equal to the height of the navbar */
}

.footer {
  flex-shrink: 0;
}