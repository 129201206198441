.content-terms-tr {
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h4 {
    font-size: 1.25rem;
    color: #106680;
    margin-top: 1.5rem;
}

p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

a {
    color: #1989b0;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
