.content-hcshmf {
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container-for-hcshmf{
    display: flex; 
    justify-content:center;
}

.team-img{
    border-radius: 50px;
}

.intro {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

h4 {
    font-size: 1.25rem;
    color: #106680;
    margin-top: 1.5rem;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0.5rem 0 1.5rem 0;
}

ul li {
    margin-bottom: 0.5rem;
}

a {
    color: #4aa2c1;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}