/* .local-impact-container {
    background-color: #e5e5f7;
    opacity: 0.3;
    background-size: 7px 7px;
    background-image: repeating-linear-gradient(45deg, #9799b7 0, #9799b7 0.7000000000000001px, #e5e5f7 0, #e5e5f7 50%);
} 

.local-impact-container {
    position: relative;
    padding: 20px; 
    flex-wrap: wrap;
  }
  */
  .local-impact-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e5e5f7;
    background-size: 7px 7px;
    background-image: repeating-linear-gradient(45deg, #9799b7 0, #9799b7 0.7px, #e5e5f7 0, #e5e5f7 50%);
    opacity: 0.3; /* Adjust opacity as needed */
    z-index: -1; /* Place the background behind the content */
  }

  /* .image-for-content{
    object-fit: cover;
    object-position: top;
    height: 300px;
  } */

  .image-list-content{
    flex-wrap: wrap;
  }

  .local-impact-container {
    position: relative;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .image-for-content {
    object-fit: cover;
    object-position: top;
    height: 200px; /* Adjusted to match the CardMedia height */
  }
  
  .content-element {
    flex-grow: 1;
  }

  /* .content-element{
    max-width: 350px;
  } */