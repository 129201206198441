.content-bta-form {
    max-width: 875px; 
    margin: 0 auto; 
    padding: 50px; 
    /* border: 1px solid #ccc;  */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px
}

.success-message-bta{
    display: flex; 
    gap: 3rem; 
    justify-content: center; 
    margin: 20rem auto;
}

.success-msg-bta-alert{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 10rem;
}