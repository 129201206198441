 /* #experts-container{
    display: flex;
    flex-direction: row;
}

.experts-box{
    border-right:1px solid rgba(221, 220, 220, 0.753);
    border-left:1px solid rgba(221, 220, 220, 0.753);
    display: flex;
    padding: 25px;
    flex-direction: column;
    width: 25%;
    align-items: center;
}

#experts-heading{
    text-align: center;
    padding: 40px;
}

.avatar-img{
    width:200px;
    height: 200px;
}
.team-member-name{
    font-size: 22px;
    font-weight: 500;
}
.team-member-bio{
    padding: 0px 15px 0px 15px;
    text-align: center;
}  */
 
 #experts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

 .experts-box {
    /* border: 1px solid rgba(221, 220, 220, 0.753); */
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 10px;
    box-sizing: border-box;
    /* max-width: 875px;  */
    /* margin: 0 auto;  */
    /* padding: 50px;  */
    /* border: 1px solid #ccc;  */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

 #experts-heading {
    text-align: center;
    padding: 40px;
}

.avatar-img {
    width: 200px;
    height: 200px;
}

 .team-member-name {
    font-size: 22px;
    font-weight: 500;
} 

 .team-member-bio {
    padding: 0 15px;
    display: flex; 
    text-align: left; 
    gap: 20px;
    align-items: center;
}


 /* @media (max-width: 1200px) {
    .experts-box {
        flex: 1 1 45%;
        max-width: 45%;
    }
} */

@media (max-width: 600px) {
    #experts-container{
        display: flex;
        flex-direction: column;
    }
    .experts-box{
        max-width: 100%;
        padding: 15px 0px;
        border:1px solid rgba(221, 220, 220, 0.753);
        margin: 5%;
    }
    .avatar-img{
        width: 150px;
        height: 150px;
    }
    .team-member-bio{
        padding: 10px 15px;
        font-size: 15px;
    }

  } 