/* #experts-container{
    display: flex;
    flex-direction: row;
}

.experts-box{
    border-right:1px solid rgba(221, 220, 220, 0.753);
    border-left:1px solid rgba(221, 220, 220, 0.753);
    display: flex;
    padding: 25px;
    flex-direction: column;
    width: 25%;
    align-items: center;
}

#experts-heading{
    text-align: center;
    padding: 40px;
}

.avatar-img{
    width:200px;
    height: 200px;
}
.team-member-name{
    font-size: 22px;
    font-weight: 500;
}
.team-member-bio{
    padding: 0px 15px 0px 15px;
    text-align: center;
} */
#experts-container-lp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.experts-box-lp {
    /* border: 1px solid rgba(221, 220, 220, 0.753); */
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 25%;
    max-width: 25%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

#experts-heading-lp {
    text-align: center;
    padding: 40px;
}

.avatar-img {
    width: 200px;
    height: 200px;
}

.team-member-name-lp {
    font-size: 22px;
    font-weight: 500;
}

.team-member-bio-lp {
    padding: 0 15px;
    text-align: left;
}


@media (max-width: 1200px) {
    .experts-box-lp {
        flex: 1 1 45%;
        max-width: 45%;
    }
}

@media (max-width: 600px) {
    #experts-container-lp {
        display: flex;
        flex-direction: column;
    }

    .experts-box-lp {
        max-width: 100%;
        padding: 15px 0px;
        border: 1px solid rgba(221, 220, 220, 0.753);
        margin: 5%;
    }

    .avatar-img-lp {
        width: 150px;
        height: 150px;
    }

    .team-member-bio-lp {
        padding: 10px 15px;
        font-size: 15px;
    }
}